<template>
	<Item
		icon="material-symbols:add-circle-outline-rounded"
		:isSelected="isSelected"
		:usage="clickHandler"
	/>
</template>

<script>
import Item from './Item.vue';
import { FOLLOWER_MEMBERSHIP_LEVEL, NONE_MEMBERSHIP_LEVEL } from '../../scripts/membershipLevels'
import { bus } from '../calendar/EventBus'
import { globalBus } from '../../GlobalEventBus';

export default {
	name: 'CreateSlotItem',
	props: {
        isSelected: Boolean,
	},
	components: {
		Item,
	},
	data() {
		return {
			isTouched: false,
			isFastMode: false,
			staticMenuIsVisible: true,
			isPreparationForDelete: false,
		};
	},

	mounted() {
		this.$el.addEventListener('touchstart', (e) => e.preventDefault());
		this.$el.addEventListener('pointerdown', (e) => {
			this.$el.setPointerCapture(e.pointerId);
			this.pointerDownHandler(e)
		})
		this.$el.addEventListener('pointermove', this.pointerMoveHandler)
		this.$el.addEventListener('pointerup', this.pointerUpHandler)
		this.$el.addEventListener('pointercancel', this.pointerUpHandler);
	},

	methods: {
		dispatchNewEvent(event, eventName) {
			let target = document.getElementById("create-slot")
			const pointerDownEvent = new PointerEvent(eventName, {
				bubbles: true,
				clientX: event.clientX,
				clientY: event.clientY
			});

			target.dispatchEvent(pointerDownEvent);
		},

		pointerDownHandler(event){
			this.isTouched = true
            if (!this.accessToCreateSlot())
                return
            
			setTimeout(() => {
				if (this.isTouched) {
					window.navigator.vibrate(65)
					this.isFastMode = true
					this.$store.dispatch('set-test-event', event)
           			setTimeout(() => this.dispatchNewEvent(event, 'pointerdown'), 50)
					bus.$emit('calendar', 'allow-scale', {allow: false})
				}
			}, 350)
        },
        pointerMoveHandler(event){
            if (!this.accessToCreateSlot())
                return

			if (this.isFastMode){
				this.dispatchNewEvent(event, 'pointermove')
				if (this.staticMenuIsVisible) {
					this.staticMenuIsVisible = false
					globalBus.$emit("static-menu", "update-visible", {visible:false})
				}
			}
        },
        pointerUpHandler(event){
			this.isTouched = false
            if (!this.accessToCreateSlot())
                return
            
			if (this.isFastMode) {
				this.dispatchNewEvent(event, 'pointerup')
				if (!this.staticMenuIsVisible) {
					this.staticMenuIsVisible = true
					globalBus.$emit("static-menu", "update-visible", {visible:true})
				}
				bus.$emit('calendar', 'allow-scale', {allow: true})
				let tableMode = this.$store.getters.tableMode
				if (!tableMode.isPlanning)
					this.$store.dispatch('update-table-mode', {isPlanning: true})
			} else {
				this.clickHandler()
			}
			this.isFastMode = false
        },

		clickHandler(){
            globalBus.$emit('slot-create', 'show', {})
        },

        accessToCreateSlot(){
            if (this.$route.name != "calendar")
                return false
        
            let calendarUid = this.$route.params.calendarUid
            let isDefault = this.$store.getters.getDefaultCalendar == calendarUid 
            let level = this.$store.getters.getMembershipLevel
            if (!isDefault && (level == NONE_MEMBERSHIP_LEVEL || level == FOLLOWER_MEMBERSHIP_LEVEL))
                return false

            return true
        }
	},
};
</script>

<style lang="scss" scoped>

</style>